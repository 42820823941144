<template>
  <div class="container elevator" v-title :data-title="$t('i18n.elevatorManagement')">
    <div id="outer-title">{{ $t("i18n.elevatorManagement") }}</div>
    <div v-loading="loading">
      <jl-table :tableData="tableData" @init="init" :options="options" :columns="columns" :operates="operates"
        :total="total" :height="$defined.HEIGHT - 360 + 'px'">
      </jl-table>
    </div>
    <el-dialog :title="$t('i18n.elevatorImport') + 'Excel:'" v-model="dialogUpload" :close-on-click-modal="false"
      destroy-on-close>
      <div class="text-center">
        <el-upload class="upload-demo" drag action=""
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :http-request="uploadSectionFile">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            <em>{{ $t("i18n.uploadAttachment") }}</em>
          </div>
        </el-upload>
      </div>
      <template #footer>
        <el-button class="el-button--cancel" @click="dialogUpload = false">
          {{ $t("i18n.cancel") }}
        </el-button>
      </template>
    </el-dialog>
    <el-dialog :title="$t('i18n.download')" v-model="dialogDownload" :close-on-click-modal="false" destroy-on-close>
      <jl-form :columns="downloadColumns" @onSubmit="onSubmit"
        @resetForm="handleDialog('dialogDownload', false)"></jl-form>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
export default {
  name: "Elevator",
  components: {
    jlTable,
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      formInline: {
        page: 1,
        pageSize: 10,
      },
      dateRange: "",
      dialogUpload: false,
      dialogDownload: false,
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "searchType",
          type: "select",
          data: proxy.$config.search,
          props: { label: "locale", value: "value" },
          rows: false,
          search: true,
        },
        {
          prop: "searchValue",
          type: "input",
          rows: false,
          search: true,
        },
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
          formatter: (row) => {
            return `<a>${row.number}</a>`;
          },
          method: (row) => {
            router.push({
              path: "/elevator/addEditElevator",
              query: { flag: 1, id: row.id },
            });
          },
        },
        {
          prop: "alias",
          label: "projectAlias",
          align: "center",
        },
        {
          prop: "projectName",
          label: "projectName",
          align: "left",
          width: 300,
        },
        {
          prop: "equipmentNumber",
          label: "equipmentNo",
          align: "center",
        },
        {
          prop: "elevatorType",
          label: "elevatorType",
          align: "center",
        },
        {
          prop: "controllerType",
          label: "controllerType",
          align: "center",
        },
        {
          prop: "station",
          label: "layerStationDoor",
          align: "center",
        },
        {
          prop: "productionTime",
          label: "productionDate",
          align: "center",
        },
        {
          prop: "intelHardwareNumber",
          label: "intelligenceCode",
          align: "center",
        },
        {
          prop: "pushable",
          label: "faultpushstatusfunction",
          align: "center",
          formatter: (row) => {
            let color = row.pushable ? "#32bff3" : "#ff3e3e";
            let name = row.pushable ? "pushon" : "pushclosed";
            if (proxy.$defined.btnPermission("电梯故障推送设置")) {
              return `<a style="color: ${color}">${t("i18n." + name)}</a>`;
            } else {
              return `<span style="color: ${color}">
                ${t("i18n." + name)}
              </span>`;
            }
          },
          method: (row) => {
            if (proxy.$defined.btnPermission("电梯故障推送设置")) {
              pushableElevator(row);
            }
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 170,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "new",
            icon: "el-icon-plus",
            class: "add",
            show: proxy.$defined.btnPermission("添加电梯"),
            type: "outer",
            method: () => {
              router.push({
                path: "/elevator/addEditElevator",
                query: { flag: 0 },
              });
            },
          },
          {
            id: "2",
            label: "import",
            icon: "el-icon-download",
            class: "import",
            show: proxy.$defined.btnPermission("导入电梯"),
            type: "outer",
            method: () => {
              handleDialog("dialogUpload", true);
            },
          },
          {
            id: "3",
            label: "export",
            icon: "el-icon-upload2",
            class: "export",
            show: proxy.$defined.btnPermission("导出数据"),
            type: "outer",
            method: (formInline) => {
              exportAllElevator(formInline);
            },
          },
          {
            id: "4",
            label: "downloadTemplate",
            icon: "el-icon-download",
            class: "transparent",
            show: true,
            type: "outer",
            formatter: () => {
              return `<a href=${proxy.$config.url.importElevator}>${t(
                "i18n.downloadTemplate"
              )}</span>`;
            },
          },
          {
            id: "5",
            label: "batchUpdate",
            icon: "el-icon-edit",
            class: "transparent",
            show: proxy.$defined.btnPermission("编辑电梯"),
            type: "outer",
            method: () => {
              router.push({
                path: "/elevator/editBatch",
              });
            },
          },
          {
            id: "6",
            label: "floorsInfo",
            icon: "el-icon-view",
            show: proxy.$defined.btnPermission("楼层信息管理"),
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              router.push({
                path: "/elevator/floorCoU",
                query: { id: row.id },
              });
            },
          },
          {
            id: "7",
            label: "export",
            icon: "el-icon-upload2",
            show: proxy.$defined.btnPermission("导出数据"),
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              state.editId = row.id;
              handleDialog("dialogDownload", true);
            },
          },
          {
            id: "8",
            label: "signUp",
            show: localStorage.getItem("userId") == '667792e285fbdbf019afd5ea30d5ae30',
            plain: false,
            type: "inner",
            method: (index, row) => {
              state.editId = row.id;
              register(row.id)
            },
          },
          // {
          //   id: "8",
          //   label: "signUp",
          //   // show: proxy.$defined.btnPermission("注册"),
          //   plain: false,
          //   // disabled: false,
          //   type: "inner",
          //   method: (index, row) => {
          //     state.editId = row.id;
          //     handleDialog("dialogDownload", true);
          //   },
          // },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
      downloadColumns: [
        {
          label: "startTime",
          prop: "startTime",
          type: "date",
        },
        {
          label: "endTime",
          prop: "endTime",
          type: "date",
        },
      ],
      editId: "",
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.system.elevatorPage(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const handleDialog = (name, flag) => {
      state[name] = flag;
    };
    const register = async (id) => {
       await proxy.$api.maintenance.getRegister(id);
    }
    const exportAllElevator = async (formInline) => {
      // 导出
      if (!formInline.searchType || !formInline.searchValue) {
        proxy.$defined.tip(t("i18n.resultMore"));
        return;
      }
      state.operates.list[2].loading = true;
      let res = await proxy.$api.system.exportAllElevator(formInline);
      proxy.$defined.exportFunc(res);
      state.operates.list[2].loading = false;
    };

    const uploadSectionFile = async (file) => {
      let param = new FormData();
      param.append("file", file.file);
      await proxy.$api.system.importElevator(param);
      proxy.$defined.tip(t("i18n.import") + t("i18n.success"), "success");
      init(state.formInline);
    };

    const onSubmit = async (row) => {
      if (!row.startTime || !row.endTime) {
        proxy.$defined.tip(t("i18n.fillInStartTime"));
      } else {
        let res = await proxy.$api.system.exportOneElevator({
          startTime: proxy.$defined.format(row.startTime),
          endTime: proxy.$defined.format(row.endTime),
          id: state.editId,
        });
        proxy.$defined.exportFunc(res);
        state.dialogDownload = false;
      }
    };

    const pushableElevator = async (row) => {
      await proxy.$api.system.pushableElevator(row.number, !row.pushable);
      init(state.formInline);
    };

    return {
      ...toRefs(state),
      init,
      handleDialog,
      exportAllElevator,
      uploadSectionFile,
      onSubmit,
      pushableElevator,
    };
  },
};
</script>

<style lang="scss">
.elevator.container {
  .el-form--inline {
    .el-input {
      line-height: unset;
    }
  }
}
</style>
